import React from "react";

import { DetailedHTMLProps } from "react";
import { FormEvent } from "react";
import { FormHTMLAttributes } from "react";
import { Link } from "gatsby"
import { MouseEvent } from "react";

import clsx from "clsx";

import Button from "@js/component/Button";
import Input from "@js/component/Input";

import * as classNames from "@css/component/LoginForm.module.scss";

/**
 * @type LoginFormProps
 */
export type LoginFormProps = Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, "children"> & {
    emailValue?: string;
    error?: string;
    onButtonClick?: (event: MouseEvent) => void;
    onEmailChange?: (event: FormEvent<HTMLInputElement>) => void;
    onPasswordChange?: (event: FormEvent<HTMLInputElement>) => void;
    passwordValue?: string;
};

/**
 * @const LoginForm
 */
const LoginForm = (props: LoginFormProps) => {
    const {
        className,
        emailValue,
        error,
        onButtonClick,
        onEmailChange,
        onPasswordChange,
        passwordValue,
        ...restProps
    } = props;

    return (
        <form { ...restProps } className={ clsx(classNames.loginForm, className) } >
            <label className={ classNames.label } htmlFor="email">E-mailadres</label>
            <Input
                id="email"
                name="email"
                onChange={ onEmailChange }
                placeholder="Typ hier je e-mailadres"
                required
                type="email"
                value={ emailValue }
            />

            <label className={ classNames.label } htmlFor="password">Wachtwoord</label>
            <Input
                id="password"
                name="password"
                onChange={ onPasswordChange }
                placeholder=""
                required
                type="password"
                value={ passwordValue }
            />
            <Button className={ classNames.button } onClick={ onButtonClick } type="submit">
                Nu inloggen
            </Button>

            { error && <div className={ classNames.error }>Deze inloggegevens zijn onjuist</div> }

            <Link className={ classNames.link } to="/portaal/wachtwoord-vergeten">
                Wachtwoord vergeten?
            </Link>
            <Link className={ classNames.link } to="/portaal/aanmelden">
                Ik heb nog geen account
            </Link>
        </form>
    );
};

export default LoginForm;
