import React from "react";

import { Helmet } from "react-helmet";

import { Dispatch } from "redux";
import { MouseEvent } from "react";

import { navigate } from "gatsby"

import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import { useState } from "react";

import Box from "@js/component/Box";
import Heading from "@js/component/Heading";
import LoginForm from "@js/component/LoginForm";
import Main from "@js/component/Main";

import StoreState from "@js/store/StoreState";

import { isLoggedIn } from "@js/services/auth";
import { setSecurity } from "@js/actionCreators/loginActionCreators";

import * as classNames from "@css/component/template/portal/Login.module.scss";

/**
 * @type MetaProps
 */
type MetaProps = {
    pageContext: {
        metaTitle: string;
        metaDescription: string;
        metaKeywords: string;
        metaUrl: string;
        title_h1: string;
    }
}

/**
 * @const AdvisorLogin
 */
const AdvisorLogin = (props: MetaProps) => {
    const {
        pageContext
    } = props;

    const dispatch = useDispatch();

    const [tempEmail, setTempEmail] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [error, setError] = useState("");

    const onLoginButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        login(tempEmail, tempPassword);
    };

    const login = (email: string, password: string) => {
        dispatch((() => async (dispatch: Dispatch, getState: () => StoreState) => {
            const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/login`, {
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });

            const data = await response.json();

            if (data.error) {
                setError(data.error);
            }
            else {
                dispatch(setSecurity(
                    data.token,
                    data.expireDate,
                    data.firstName,
                    data.lastName,
                    data.username
                ));

                navigate("/portaal/dashboard");
            }
        })());
    };

    useLayoutEffect(() => {
        if (isLoggedIn()) {
            navigate("/portaal/dashboard", { replace: true });
        }
    }, []);

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>{ pageContext.metaTitle ? pageContext.metaTitle : "Login" }</title>
                { pageContext.metaDescription && <meta content={pageContext.metaDescription} name="description" /> }
                { pageContext.metaDescription && <meta content={pageContext.metaDescription} property="og:description" /> }
                { pageContext.metaTitle && <meta content={pageContext.metaTitle } property="og:title" /> }
                { pageContext.metaKeywords && <meta content={pageContext.metaKeywords } property="og:keywords" /> }
                { pageContext.metaUrl && <meta content={pageContext.metaUrl } property="og:url" /> }
                <meta content="woninglabel.nl" property="og:site_name" />
            </Helmet>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="large">
                    { pageContext.title_h1 && pageContext.title_h1 }
                    { !pageContext.title_h1 &&
                        <>
                            Inloggen voor <strong>adviseurs</strong>
                        </>
                    }
                </Heading>
                <Box className={ classNames.box }>
                    <LoginForm
                        error={ error }
                        onButtonClick={ onLoginButtonClick }
                        onEmailChange={ (event) => {
                            setTempEmail(event.currentTarget.value);
                        }}
                        onPasswordChange={ (event) => {
                            setTempPassword(event.currentTarget.value);
                        }}
                    />
                </Box>
            </div>
        </Main>
    );
};

export default AdvisorLogin;
